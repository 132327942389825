import './OrderInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import { ColorBadge } from 'components/ColorBadge/ColorBadge';
import moment from 'moment';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

export const OrderInteractionCard = (props: InteractionCardProps) => {
    
    const roles = props.interaction.User?.Roles?.map(r => r.nmRole).join("; ");

    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={props.interaction.type ?? Resources.get('InteractionPanelType', 'SLORD','label')}
            code={props.code}
            icon={{
                name: "shopping-cart-2",
                backgroundColor: 'var(--color_green_5)'
            }}
            onPress={()=>{}}
        >
        <div className="OrderInteractionCard">
            {!!props.userName && <div className="order-interaction-user">
                <label>{props.userName}</label>
            </div>}
            {!!roles && <div className="order-user-roles">
                <label>{roles}</label>
            </div>}
            {(!!props.previousStatus || !!props.newStatus) && <div className="order-interaction-status-bar">
                {!!props.previousStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                    semanticValue={props.previousStatus.cdSemanticValue ?? ""}
                    text={props.previousStatus.dsStatus ?? ""}/>
                }

                {!!props.previousStatus && !!props.newStatus && (
                    <TrendingFlatIcon className="order-interaction-arrow-icon"/>
                )}

                {!!props.newStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                    semanticValue={props.newStatus.cdSemanticValue ?? ""}
                    text={props.newStatus.dsStatus ?? ""}/>
                }                    
            </div>}

            {(!!props.interaction.EntityFields?.dtOrder) && <div className="order-interaction-order-date">
                <label className="order-interaction-order-date-label">{Resources.get('OrderInteractionPanel', 'OrderDate','label')}</label>
                <label className="order-interaction-order-date-value">{moment(props.interaction.EntityFields.dtOrder).format('ddd, D MMM H:mm A')}</label>
            </div>}
            {(!!props.interaction.EntityFields?.dtDelivery) && <div className="order-interaction-order-date">
                <label className="order-interaction-order-date-label">{Resources.get('OrderInteractionPanel', 'DeliveryDate','label')}</label>
                <label className="order-interaction-order-date-value">{moment(props.interaction.EntityFields.dtDelivery).format('ddd, D MMM H:mm A')}</label>
            </div>}             
        </div>
        </GenericInteractionCard>
    );
}