import './PhoneCallInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';


export const PhoneCallInteractionCard = (props: InteractionCardProps) => {
    const roles = props.interaction.User?.Roles?.map(r => r.nmRole).join("; ");

    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'CALL','label')}
            code={props.code}
            icon={{
                name: "phone-enabled",
                backgroundColor: 'var(--color_orange_5)'
            }}
            onPress={()=>{}}
        >
            <div className="PhoneCallInteractionCard">
                {(!!props.userName || !!props.interactionDate) && <div className="call-interaction-user">
                    {!!props.userName && <label>{props.userName}</label>}
                    {(!!props.userName && !!props.interactionDate) && <FiberManualRecordIcon className="call-interaction-dot-icon"/>}
                    {!!props.interactionDate && <label>{moment(props.interactionDate).format('ddd, D MMM H:mm A')}</label>}                    
                </div>}
                {!!roles && <div className="call-user-roles">
                    <label>{roles}</label>
                </div>
                }
                {(!!props.interaction.EntityFields?.dsRecipient) && <div className="call-interaction-extra-field">
                    <label className="call-interaction-extra-label">{Resources.get('CallInteractionPanel', 'Recipient','label')}</label>
                    <label className="call-interaction-extra-value">{props.interaction.EntityFields.dsRecipient}</label>
                </div>}
                {(!!props.interaction.EntityFields?.dsNotes) && <div className="call-interaction-notes">
                    <label>{props.interaction.EntityFields.dsNotes}</label>
                </div>}             
            </div>
        </GenericInteractionCard>
    );
}