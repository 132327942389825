import './NoteInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";


export const NoteInteractionCard = (props: InteractionCardProps) => {
    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'NOTE','label')}
            code={props.code}
            icon={{
                name: "sticky-note-2",
                backgroundColor: 'var(--color_yellow_5)'
            }}
            onPress={()=>{}}
        >
        <div className="NoteInteractionCard">
            {!!props.userName && <div className="note-interaction-user">
                <label>{props.userName}</label>
            </div>}            
            {!!props.interaction.EntityFields?.dsNote && <div className="note-interaction-notes">
                <label>{props.interaction.EntityFields?.dsNote}</label>
            </div>}
        </div>
        </GenericInteractionCard>
    );
}