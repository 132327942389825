import "./index.scss";
function hexToRgb(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number) {
  return (
    "#" +
    [r, g, b]
      .map((v) =>
        Math.max(0, Math.min(255, Math.round(v)))
          .toString(16)
          .padStart(2, "0")
      )
      .join("")
  ).toUpperCase();
}

function generateBaseY(colorX: string) {
  const { r, g, b } = hexToRgb(colorX);

  const newR = r + (93 - 0);
  const newG = g + (173 - 174);
  const newB = b + (225 - 239);

  return rgbToHex(newR, newG, newB);
}

function adjustLightness(hex: string, factor: number) {
  const { r, g, b } = hexToRgb(hex);
  const lFactor = factor / 100;

  let adjustedRgb;
  if (lFactor >= 0) {
    adjustedRgb = {
      r: r + (255 - r) * lFactor,
      g: g + (255 - g) * lFactor,
      b: b + (255 - b) * lFactor,
    };
  } else {
    adjustedRgb = {
      r: r + (0 - r) * -lFactor,
      g: g + (0 - g) * -lFactor,
      b: b + (0 - b) * -lFactor,
    };
  }

  return rgbToHex(adjustedRgb.r, adjustedRgb.g, adjustedRgb.b);
}


function generateVariantsFromX(colorX: string) {
  const baseY = generateBaseY(colorX);

  return {
    lighter60: adjustLightness(baseY, 60),
    lighter40: adjustLightness(baseY, 40),
    lighter20: adjustLightness(baseY, 20),
    base: baseY,
    darker20: adjustLightness(baseY, -20),
    darker40: adjustLightness(baseY, -40),
    darker60: adjustLightness(baseY, -60),
  };
}

function hexToRgbString(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}


const colors = {
  // Secondary
  secondary: '#00a79d',

  // Blue
  blue_0: '#00AEEF',
  blue_1: '#D6EAF8',
  blue_2: '#AED6F0',
  blue_3: '#85C1E9',
  blue_4: '#5DADE1',
  blue_5: '#3498DA',
  blue_6: '#2A7AAE',
  blue_7: '#1F5B83',

  // Black
  black_0: '#000000',
  black_1: '#262626',

  // Gray
  gray_1: '#f1f4f8',
  gray_2: '#E0E2E6',
  gray_3: '#BDBEC3',
  gray_4: '#ACACB1',
  gray_5: '#9C9BA0',
  gray_6: '#8B8A8E',
  gray_7: '#7A787C',

  // White
  white_1: '#ffffff',

  // Green
  green_1: '#D5F5E3',
  green_2: '#ACEBC6',
  green_3: '#82E0AA',
  green_4: '#59D68D',
  green_5: '#2FCC71',
  green_6: '#26A35A',
  green_7: '#1C7A44',

  // Red
  red_1: '#FADBD8',
  red_2: '#F5B7B1',
  red_3: '#F19489',
  red_4: '#EC7062',
  red_5: '#E74C3B',
  red_6: '#B93D2F',
  red_7: '#8B2E23',

  // Yellow
  yellow_1: '#FCF3CF',
  yellow_2: '#F9E89E',
  yellow_3: '#F7DC6E',
  yellow_4: '#F4D13D',
  yellow_5: '#F1C50D',
  yellow_6: '#C19E0A',
  yellow_7: '#917608',

  // Orange
  orange_1: '#FAE5D3',
  orange_2: '#F5CBA7',
  orange_3: '#F0B17B',
  orange_4: '#EB974F',
  orange_5: '#E67D23',
  orange_6: '#B8641C',
  orange_7: '#8A4B15',

  // Purple
  purple_1: '#EBDEF0',
  purple_2: '#D7BDE2',
  purple_3: '#C39BD3',
  purple_4: '#AF7AC5',
  purple_5: '#9B59B6',
  purple_6: '#7C4792',
  purple_7: '#5D356D',
};

export const themeColor = () => {
  // const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#00aeef";
  const primaryColor = '#ff0000'
  const variants = generateVariantsFromX(primaryColor);

  //Primary Theme
  document.documentElement.style.setProperty("--color_primary", primaryColor);
  document.documentElement.style.setProperty("--color_primary_1", variants.lighter60);
  document.documentElement.style.setProperty("--color_primary_2", variants.lighter40);
  document.documentElement.style.setProperty("--color_primary_3", variants.lighter20);
  document.documentElement.style.setProperty("--color_primary_4", variants.base);
  document.documentElement.style.setProperty("--color_primary_5", variants.darker20);
  document.documentElement.style.setProperty("--color_primary_6", variants.darker40);
  document.documentElement.style.setProperty("--color_primary_7", variants.darker60);

  // Secondary
document.documentElement.style.setProperty('--color_secondary', colors.secondary);

// Blue
document.documentElement.style.setProperty('--color_blue_0', colors.blue_0);
document.documentElement.style.setProperty('--color_blue_1', colors.blue_1);
document.documentElement.style.setProperty('--color_blue_2', colors.blue_2);
document.documentElement.style.setProperty('--color_blue_3', colors.blue_3);
document.documentElement.style.setProperty('--color_blue_4', colors.blue_4);
document.documentElement.style.setProperty('--color_blue_5', colors.blue_5);
document.documentElement.style.setProperty('--color_blue_6', colors.blue_6);
document.documentElement.style.setProperty('--color_blue_7', colors.blue_7);

// Black
document.documentElement.style.setProperty('--color_black_0', colors.black_0);
document.documentElement.style.setProperty('--color_black_1', colors.black_1);

// Gray
document.documentElement.style.setProperty('--color_gray_1', colors.gray_1);
document.documentElement.style.setProperty('--color_gray_2', colors.gray_2);
document.documentElement.style.setProperty('--color_gray_3', colors.gray_3);
document.documentElement.style.setProperty('--color_gray_4', colors.gray_4);
document.documentElement.style.setProperty('--color_gray_5', colors.gray_5);
document.documentElement.style.setProperty('--color_gray_6', colors.gray_6);
document.documentElement.style.setProperty('--color_gray_7', colors.gray_7);

// White
document.documentElement.style.setProperty('--color_white_1', colors.white_1);

// Green
document.documentElement.style.setProperty('--color_green_1', colors.green_1);
document.documentElement.style.setProperty('--color_green_2', colors.green_2);
document.documentElement.style.setProperty('--color_green_3', colors.green_3);
document.documentElement.style.setProperty('--color_green_4', colors.green_4);
document.documentElement.style.setProperty('--color_green_5', colors.green_5);
document.documentElement.style.setProperty('--color_green_6', colors.green_6);
document.documentElement.style.setProperty('--color_green_7', colors.green_7);

// Red
document.documentElement.style.setProperty('--color_red_1', colors.red_1);
document.documentElement.style.setProperty('--color_red_2', colors.red_2);
document.documentElement.style.setProperty('--color_red_3', colors.red_3);
document.documentElement.style.setProperty('--color_red_4', colors.red_4);
document.documentElement.style.setProperty('--color_red_5', colors.red_5);
document.documentElement.style.setProperty('--color_red_6', colors.red_6);
document.documentElement.style.setProperty('--color_red_7', colors.red_7);

// Yellow
document.documentElement.style.setProperty('--color_yellow_1', colors.yellow_1);
document.documentElement.style.setProperty('--color_yellow_2', colors.yellow_2);
document.documentElement.style.setProperty('--color_yellow_3', colors.yellow_3);
document.documentElement.style.setProperty('--color_yellow_4', colors.yellow_4);
document.documentElement.style.setProperty('--color_yellow_5', colors.yellow_5);
document.documentElement.style.setProperty('--color_yellow_6', colors.yellow_6);
document.documentElement.style.setProperty('--color_yellow_7', colors.yellow_7);

// Orange
document.documentElement.style.setProperty('--color_orange_1', colors.orange_1);
document.documentElement.style.setProperty('--color_orange_2', colors.orange_2);
document.documentElement.style.setProperty('--color_orange_3', colors.orange_3);
document.documentElement.style.setProperty('--color_orange_4', colors.orange_4);
document.documentElement.style.setProperty('--color_orange_5', colors.orange_5);
document.documentElement.style.setProperty('--color_orange_6', colors.orange_6);
document.documentElement.style.setProperty('--color_orange_7', colors.orange_7);

// Purple
document.documentElement.style.setProperty('--color_purple_1', colors.purple_1);
document.documentElement.style.setProperty('--color_purple_2', colors.purple_2);
document.documentElement.style.setProperty('--color_purple_3', colors.purple_3);
document.documentElement.style.setProperty('--color_purple_4', colors.purple_4);
document.documentElement.style.setProperty('--color_purple_5', colors.purple_5);
document.documentElement.style.setProperty('--color_purple_6', colors.purple_6);
document.documentElement.style.setProperty('--color_purple_7', colors.purple_7);

  //RGBs
  document.documentElement.style.setProperty('--color_white--rgb', hexToRgbString(colors.white_1));
document.documentElement.style.setProperty('--color_primary--rgb', hexToRgbString(primaryColor));
document.documentElement.style.setProperty('--color_primary_1--rgb', hexToRgbString(variants.lighter60));
document.documentElement.style.setProperty('--color_primary_2--rgb', hexToRgbString(variants.lighter40));
document.documentElement.style.setProperty('--color_primary_3--rgb', hexToRgbString(variants.lighter20));
document.documentElement.style.setProperty('--color_primary_4--rgb', hexToRgbString(variants.base));
document.documentElement.style.setProperty('--color_primary_5--rgb', hexToRgbString(variants.darker20));
document.documentElement.style.setProperty('--color_primary_6--rgb', hexToRgbString(variants.darker40));
document.documentElement.style.setProperty('--color_primary_7--rgb', hexToRgbString(variants.darker60));
document.documentElement.style.setProperty('--color_secondary--rgb', hexToRgbString(colors.secondary));
document.documentElement.style.setProperty('--color_black_0--rgb', hexToRgbString(colors.black_0));
document.documentElement.style.setProperty('--color_black_1--rgb', hexToRgbString(colors.black_1));
document.documentElement.style.setProperty('--color_gray_1--rgb', hexToRgbString(colors.gray_1));
document.documentElement.style.setProperty('--color_gray_2--rgb', hexToRgbString(colors.gray_2));
document.documentElement.style.setProperty('--color_gray_3--rgb', hexToRgbString(colors.gray_3));
document.documentElement.style.setProperty('--color_gray_6--rgb', hexToRgbString(colors.gray_6));
document.documentElement.style.setProperty('--color_gray_7--rgb', hexToRgbString(colors.gray_7));
document.documentElement.style.setProperty('--color_blue_5--rgb', hexToRgbString(colors.blue_5));
document.documentElement.style.setProperty('--color_red_5--rgb', hexToRgbString(colors.red_5));
document.documentElement.style.setProperty('--color_red_6--rgb', hexToRgbString(colors.red_6));
document.documentElement.style.setProperty('--color_green_1--rgb', hexToRgbString(colors.green_1));
document.documentElement.style.setProperty('--color_green_5--rgb', hexToRgbString(colors.green_5));
document.documentElement.style.setProperty('--color_green_6--rgb', hexToRgbString(colors.green_6));
document.documentElement.style.setProperty('--color_purple_2--rgb', hexToRgbString(colors.purple_2));
document.documentElement.style.setProperty('--color_purple_4--rgb', hexToRgbString(colors.purple_4));
document.documentElement.style.setProperty('--color_orange_5--rgb', hexToRgbString(colors.orange_5));
document.documentElement.style.setProperty('--color_yellow_5--rgb', hexToRgbString(colors.yellow_5));
};
