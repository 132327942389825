import './SurveyInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";


export const SurveyInteractionCard = (props: InteractionCardProps) => {
    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'QINST','label')}
            code={props.code}
            previousStatus={props.previousStatus}
            newStatus={props.newStatus}
            icon={{
                name: "content-paste",
                backgroundColor: 'var(--color_primary_6)'
            }}
            interactionDate={props.interactionDate}
            userName={props.userName}
            onPress={()=>{}}
        >
            {!!props.interaction.EntityFields?.dsQuestInstance && <div className="SurveyInteractionCard">
                <label>{props.interaction.EntityFields?.dsQuestInstance}</label>
            </div>}
        </GenericInteractionCard>
    );
}