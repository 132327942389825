import './PriceAgreementInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import { ColorBadge } from 'components/ColorBadge/ColorBadge';


export const PriceAgreementInteractionCard = (props: InteractionCardProps) => {
    const signedBy = props.interaction.additionalValues;

    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'CSPRA','label')}
            code={props.code}
            icon={{
                name: "verified",
                backgroundColor: "var(--color_red_5)"
            }}
            onPress={()=>{}}
        >
        <div className="PriceAgreementInteractionCard">
            <div className="price-agreement-name-and-status">
                {!!props.interaction.EntityFields?.dsPriceLetter && <div className="price-agreement-interaction-user">
                    <label>{props.interaction.EntityFields?.dsPriceLetter}</label>
                </div>}
                {!!props.newStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                        semanticValue={props.newStatus.cdSemanticValue ?? ""}
                        text={props.newStatus.dsStatus ?? ""}/>
                }                
            </div>
            {!!props.userName && <div className="price-agreement-extra-field">
                <label className="price-agreement-extra-field-label">{Resources.get('PriceAgreementInteractionPanel', 'CreatedBy','label')}</label>
                <label className="price-agreement-extra-field-value">{props.userName}</label>
            </div>}
            {(!!signedBy?.dsName) && <div className="price-agreement-extra-field">
                <label className="price-agreement-extra-field-label">{Resources.get('PriceAgreementInteractionPanel', 'SignedBy','label')}</label>
                <label className="price-agreement-extra-field-value">{signedBy?.dsName}</label>
            </div>}   
        </div>  
        </GenericInteractionCard>
    );
}